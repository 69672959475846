import { useState } from "react";
import GatewayTable from "./gatewayTable";
import SensorTable from "./sensorTable";
import GatewaySensorPairsTable from "./gatewaySensorPairsTable";


function App() {
  const [display, setDisplay] = useState("GATEWAY")

  return (
    <div className="App">
      <button onClick={() => setDisplay("GATEWAY")}>Gateway</button>
      <button onClick={() => setDisplay("SENSOR")}>Sensor</button>
      <button onClick={() => setDisplay("PAIRS")}>Gateway-Sensor Pairs</button>
      {display === "GATEWAY" && <GatewayTable />}
      {display === "SENSOR" && <SensorTable />}
      {display === "PAIRS" && <GatewaySensorPairsTable />}
    </div>
  );
}

export default App;

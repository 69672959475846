import { useState, useEffect } from "react"
import fetchSensors from "./helperFunctions/fetchSensors"
import fetchGateways from "./helperFunctions/fetchGateways"
import generateTireLocationString from "./helperFunctions/generateTireLocationString"
import generateTruckPlanString from "./helperFunctions/generateTruckPlanString"

/*
Creates the table displaying sensors
Allows user to change the paired-gateway property for sensors
*/

function SensorTable() {
    const [sensorData, setSensorData] = useState([])
    const [gatewayData, setGatewayData] = useState([])
    const [tableSettings, setTableSettings] = useState({device_id: true, timestamp_added: true})

    // fetch sensors and gateways from DB
    // gateway data needed for gateway ID autofill
    useEffect(() => {
      async function fetchData() {
          const fetchedSensorData = await fetchSensors()
          // default filter only shows non-paired sensors
          const filteredSensors = fetchedSensorData.filter((sensor) => sensor.paired_gateway === null).sort((sensor1, sensor2) => sensor2.id - sensor1.id)
          setSensorData(filteredSensors)

          const fetchedGatewayData = await fetchGateways()
          setGatewayData(fetchedGatewayData)
      }
      fetchData()
    }, [])

    // filter to show only processed/unprocessed sensors
    async function filterSensors(filter) {
      const updatedSensorData = await fetchSensors()

      if (filter === "PROCESSED") {
          const processedSensorData = updatedSensorData.filter((sensor) => 
          sensor.paired_gateway !== null).sort((sensor1, sensor2) => sensor2.id - sensor1.id)
          setSensorData(processedSensorData)
      }
      else if (filter === "UNPROCESSED") {
          const unprocessedSensorData = updatedSensorData.filter((sensor) => 
          sensor.paired_gateway === null).sort((sensor1, sensor2) => sensor2.id - sensor1.id)
          setSensorData(unprocessedSensorData)
      }
    }

    // update paired gateway in DB
    async function changeGatewayPair(sensorID, paired_gateway) {
      await fetch("https://backupdroplet.mesomat.org/api/staging-area/sensor/paired_gateway", 
        {headers: {'Content-Type': 'application/json', 'token': 'super-secret-password'}, 
        method: "PUT", 
        body: JSON.stringify({entity_id: sensorID, paired_gateway: paired_gateway})})
    }

    return (
      <div>
        {/* filter buttons */}
        <button onClick={() => setTableSettings({...tableSettings, device_id: !tableSettings.device_id})}>
            {!tableSettings.device_id ? "Hide" : "Show"} Device ID
        </button>
        <button onClick={() => setTableSettings({...tableSettings, timestamp_added: !tableSettings.timestamp_added})}>
            {!tableSettings.timestamp_added ? "Hide" : "Show"} timestamp Added
        </button>
        <select onChange={(v) => filterSensors(v.target.value)}>
            <option value={"UNPROCESSED"}>UNPROCESSED</option> 
            <option value={"PROCESSED"}>PROCESSED</option> 
        </select>

        <table>
          {/* table headers */}
          <tr>
              <th>ID</th>
              <th>Sensor Barcode</th>
              <th>Tire Position</th>
              <th>Truck Plan</th>
              {!tableSettings.device_id && <th>Device ID</th>}
              {!tableSettings.timestamp_added && <th>Timestamp Added</th>}
              <th>Paired Gateway (Serial)</th>
          </tr>
          {/* table rows */}
          {sensorData.map((sensor) => 
          <tr key={sensor.id + sensor.paired_gateway}>
            <td>{sensor.id}</td>
            <td>{sensor.sensor_barcode}</td>
            <td>{generateTireLocationString(sensor)}</td>
            <td>{generateTruckPlanString(sensor.truck_plan)}</td>
            <td>
              <input type="text" list="gateway_pairs"
                onBlur={(v) => changeGatewayPair(sensor.id, v.target.value)} defaultValue={sensor.paired_gateway}>
              </input>
              <datalist id="gateway_pairs">
                  {[... new Set(gatewayData.map((item) => item.gateway_serial))].map((gateway_serial) => 
                    <option>{gateway_serial}</option>
                  )}
              </datalist>
            </td>
            {!tableSettings.device_id && <td>{sensor.device_id}</td>}
            {!tableSettings.timestamp_added && <td>{sensor.timestamp_added}</td>}
          </tr>
          )}
        </table>
      </div>
    );
  }
  
  export default SensorTable;
  
import { useEffect, useState } from "react";
import fetchGateways from "./helperFunctions/fetchGateways";

/*
Creates the table displaying gateways
Allows user to change the processed_flag property for gateways
*/

function GatewayTable() {
    const [gatewaysData, setGatewaysData] = useState([])
    const [tableSettings, setTableSettings] = useState({device_id: true, timestamp_added: true})

    // fetch gateways from DB
    useEffect(() => {
        async function fetchData() {
            const gatewaysData = await fetchGateways()
            // default filter only shows unprocessed gateways
            setGatewaysData(gatewaysData.filter((gateway) => gateway.processed_flag === "200").sort((gateway1, gateway2) => gateway2.id - gateway1.id))
        }
        fetchData()
    }, [])

    // filter to show only processed/unprocessed/on hold gateways
    async function filterGateways(filter) {
        const updatedGatewaysData = await fetchGateways()

        if (filter === "PROCESSED") {
            const filteredGatewayCopy = updatedGatewaysData.filter((gateway) => 
                gateway.processed_flag === "100").sort((gateway1, gateway2) => gateway2.id - gateway1.id)
            setGatewaysData(filteredGatewayCopy)
        }
        else if (filter === "UNPROCESSED") {
            const filteredGatewayCopy = updatedGatewaysData.filter((gateway) => 
                gateway.processed_flag === "200").sort((gateway1, gateway2) => gateway2.id - gateway1.id)
            setGatewaysData(filteredGatewayCopy)
        }
        else if (filter === "ON_HOLD") {
            const filteredGatewayCopy = updatedGatewaysData.filter((gateway) => 
                gateway.processed_flag === "150").sort((gateway1, gateway2) => gateway2.id - gateway1.id)
            setGatewaysData(filteredGatewayCopy)
        }
    }

    // update processed flag in DB
    async function changeProcessedFlag(gatewayID, flagValue) {
        await fetch("https://backupdroplet.mesomat.org/api/staging-area/gateway/processed_flag", 
            {headers: {'Content-Type': 'application/json', 'token': 'super-secret-password'}, 
            method: "PUT", 
            body: JSON.stringify({entity_id: gatewayID, processed_flag: flagValue})})
    }

    // update customer ID in DB
    async function changeCustomerID(gatewayID, customerID) {
        await fetch("https://backupdroplet.mesomat.org/api/staging-area/gateway/customer_id", 
          {headers: {'Content-Type': 'application/json', 'token': 'super-secret-password'}, 
          method: "PUT", 
          body: JSON.stringify({entity_id: gatewayID, customer_id: customerID})})
      }

    return (
    <div>
        {/* filter buttons */}
        <button onClick={() => setTableSettings({...tableSettings, device_id: !tableSettings.device_id})}>
            {!tableSettings.device_id ? "Hide" : "Show"} Device ID
        </button>
        <button onClick={() => setTableSettings({...tableSettings, timestamp_added: !tableSettings.timestamp_added})}>
            {!tableSettings.timestamp_added ? "Hide" : "Show"} timestamp Added
        </button>
        <select onChange={(v) => filterGateways(v.target.value)}>
            <option value={"UNPROCESSED"}>UNPROCESSED</option> 
            <option value={"PROCESSED"}>PROCESSED</option> 
            <option value={"ON_HOLD"}>ON HOLD</option> 
        </select>

        <table>
            {/* table headers */}
            <tr>
                <th>ID</th>
                <th>Gateway Serial</th>
                <th>Gateway Name</th>
                <th>Customer Name</th>
                {!tableSettings.device_id && <th>Device ID</th>}
                {!tableSettings.timestamp_added && <th>Timestamp Added</th>}
                <th>Processed Flag</th>
                <th>Customer ID</th>
            </tr>
            {/* table rows */}
            {gatewaysData.map((gateway) => 
            <tr key={gateway.id + gateway.processed_flag}>
                <td>{gateway.id}</td>
                <td>{gateway.gateway_serial}</td>
                <td>{gateway.gateway_name}</td>
                <td>{gateway.customer_name}</td>
                {!tableSettings.device_id && <td>{gateway.device_id}</td>}
                {!tableSettings.timestamp_added && <td>{gateway.timestamp_added}</td>}
                <td>
                    <select onChange={(v) => changeProcessedFlag(gateway.id, v.target.value)}>
                        <option value={"100"} selected={gateway.processed_flag === "100" ? true : false}>100 (PROCESSED)</option>    
                        <option value={"200"} selected={gateway.processed_flag === "200" ? true : false}>200 (UNPROCESSED)</option>
                        <option value={"150"} selected={gateway.processed_flag === "150" ? true : false}>150 (ON HOLD)</option>        
                    </select>
                </td>
                <td>
                    <input type="text"
                        onBlur={(v) => changeCustomerID(gateway.id, v.target.value)} defaultValue={gateway.customer_id}>
                    </input>
                </td>
            </tr>
        )}
        </table>
    </div>
  );
}

export default GatewayTable;

import { useEffect, useState } from "react";
import fetchGatewaySensorPairs from "./helperFunctions/fetchGatewaySensorPairs";
import generateTireLocationString from "./helperFunctions/generateTireLocationString";
import generateTruckPlanString from "./helperFunctions/generateTruckPlanString";

/*
Creates the table displaying sensors that are paired to a gateway
Allows user to add the paired data to the DB
*/

function GatewaySensorPairsTable() {
    const [gatewaySensorPairsData, setGatewaySensorPairsData] = useState([])

    // fetch gateway-sensors pairs from DB
    useEffect(() => {
        async function fetchData() {
            const gatewaySensorPairs = await fetchGatewaySensorPairs()
            setGatewaySensorPairsData(gatewaySensorPairs.filter((pair) => pair.processed_flag === "100").sort((pair1, pair2) => pair2.gateway_serial - pair1.gateway_serial))
        }
        fetchData()
    }, [])

    // add a gateway-sensor pair to the main DB
    async function addGatewayToDB(pair) {
        // create suitable data for the main DB routes
        const gatewayPayload = {
            customer_id: pair.customer_id,
            hub_name: pair.gateway_name,
            hub_serial: pair.gateway_serial,
            truck_plan: pair.truck_plan
        }

        // check for unfilled fields 
        if (!gatewayPayload.hub_serial || !gatewayPayload.hub_name || !gatewayPayload.customer_id || !gatewayPayload.truck_plan
        ) {
            alert("Unfilled fields")
            return;
        }

        // add to gateway route, display any errors
        try {
            const response = await fetch("https://driverapp.eastus.cloudapp.azure.com/install/hub", 
                {headers: {'Content-Type': 'application/json'}, 
                method: "PUT", 
                body: JSON.stringify(gatewayPayload)})
            console.log(response)
        }
        catch (error) {
            alert(error)
        }
    }

    async function addSensorToDB(pair) {
        // create suitable data for the main DB routes
        const sensorPayload = {
            sensor_serial: 120, // dummy value
            barcode: pair.sensor_barcode,
            hub_serial: pair.gateway_serial,
            tire_position: pair.tire_position,
        }

        // check for unfilled fields
        if (!sensorPayload.sensor_serial || !sensorPayload.barcode || !sensorPayload.hub_serial || !sensorPayload.tire_position) {
            alert("Unfilled fields")
            return;
        }

        // add to sensor route, display any errors
        try {
            const response = await fetch("https://driverapp.eastus.cloudapp.azure.com/install/sensor", 
                {headers: {'Content-Type': 'application/json'}, 
                method: "PUT", 
                body: JSON.stringify(sensorPayload)})
            console.log(response)
        }
        catch (error) {
            alert(error)
        }
    }

    return (
    <div>
        <table>
            <tr>
                <th>Customer ID</th>
                <th></th>
                <th>Gateway Serial</th>
                <th>Gateway Name</th>
                <th>Truck Plan</th>
                <th></th>
                <th>Sensor Barcode</th>
                <th>Tire Position</th>
                <th></th>
                <th>Add Gateway</th>
                <th>Add Sensor</th>
            </tr>
            {gatewaySensorPairsData.map((pair) => 
            <tr>
                <td>{pair.customer_id}</td>
                <td style={{width: 50}}></td>
                <td>{pair.gateway_serial}</td>
                <td>{pair.gateway_name}</td>
                <td>{generateTruckPlanString(pair.truck_plan)}</td>
                <td style={{width: 50}}></td>
                <td>{pair.sensor_barcode}</td>
                <td>{generateTireLocationString(pair)}</td>
                <td style={{width: 50}}></td>
                <td><button onClick={() => addGatewayToDB(pair)}>Add gateway</button></td>
                <td><button onClick={() => addSensorToDB(pair)}>Add sensor</button></td>
            </tr>
        )}
        </table>
    </div>
  );
}

export default GatewaySensorPairsTable;
